<template>
  <v-menu v-model="userMenu" v-if="profile">
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-chip v-if="$vuetify.breakpoint.smAndUp" pill class="mx-3">
          <v-avatar dark color="secondary white--text" left>
            {{ initials }}
          </v-avatar>
          {{ profile.name || profile.email }}
          <v-icon right> mdi-menu-down </v-icon>
        </v-chip>
        <v-avatar
          v-if="$vuetify.breakpoint.xsOnly"
          dark
          size="32"
          color="secondary white--text"
        >
          {{ initials }}
        </v-avatar>
      </div>
    </template>
    <v-card>
      <v-list dark>
        <v-list-item two-line>
          <v-list-item-avatar color="grey">
            {{ initials }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-if="profile.name">
              {{ profile.name }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="false && profile.company">
              {{ profile.company }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption">
              {{ profile.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense light>
        <v-list-item to="/admin/moderation" v-if="isAdmin">
          <v-list-item-action>
            <v-icon>mdi-forum</v-icon>
          </v-list-item-action>
          <v-list-item-subtitle>Q&A Moderation</v-list-item-subtitle>
        </v-list-item>
        <v-list-item to="/admin/polls" v-if="false && isAdmin">
          <v-list-item-action>
            <v-icon>mdi-signal</v-icon>
          </v-list-item-action>
          <v-list-item-subtitle>Polls Moderation</v-list-item-subtitle>
        </v-list-item>
        <v-list-item to="/stage" v-if="false && isAdmin">
          <v-list-item-action>
            <v-icon>mdi-video</v-icon>
          </v-list-item-action>
          <v-list-item-subtitle>Main Stage</v-list-item-subtitle>
        </v-list-item>
        <v-divider />
        <v-list-item @click="signOut">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-subtitle>
            {{ $t('auth.logout') }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ProfileAppBar',
  components: {},
  data() {
    return {
      userMenu: false
    };
  },
  computed: {
    ...mapState({
      profile: state => state.auth.user
    }),
    ...mapGetters({
      initials: 'auth/initials',
      isAdmin: 'auth/isAdmin'
    })
  },
  methods: {
    async signOut() {
      await this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>

<i18n>
{
    "en": {
        "logout": "Logout"
    },
    "tr": {
        "logout": "Çıkış Yap"
    }
}
</i18n>
